import React from 'react'
import { Table, Button, Modal, List, Loader, Popup, Input, Checkbox, Select, Sticky, Icon } from 'semantic-ui-react';

const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
]

class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLicense: [],
      download_loading: false,
      filter_tconfig: ''
    };
  }

  componentDidMount = () => {
    if (typeof this.props.datas === 'undefined') {
      if (typeof this.props.showuser === 'undefined') {
        if (this.props.location.pathname !== '/') this.props.retrieveUser(this.props.location.pathname.split('/admin/')[1]);
        else this.props.retrieveUser(this.props.auth);
      }
      else this.props.retrieveUser(this.props.showuser);
    }
  }

  renderHistoryModal = (per_license) => {
    return (
      <Modal
        trigger={<Button basic color='violet' icon='eye' label={{ as: 'a', basic: true, color: 'violet', content: per_license.history }} labelPosition='left' size='small' />}
        closeIcon
        open={this.state.historymodal}
        onClose={() => { this.setState({ historymodal: false }); this.props.cleanHistory() }}
        onOpen={() => { this.props.retrieveHistory(per_license.license); this.setState({ historymodal: true }) }}
        basic
      >
        <Modal.Content>
          {typeof this.props.history !== 'undefined' && <List items={this.props.history} horizontal floated='left' verticalAlign='middle' size='large' />}
        </Modal.Content>
      </Modal>
    )
  }

  renderExtendPop = (username) => {
    let content = <React.Fragment>
      <Input type='date' name='expiration' size='small' onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} value={this.state.expiration || ''} style={{ width: '250px' }} icon='calendar plus outline' iconPosition='left' />
      <Button circular basic color='red' icon='dont' floated='right' style={{ marginTop: '5px' }} size='tiny' onClick={() => { this.setState({ expiration: undefined, [popid]: false }) }} />
      <Button circular basic color='green' icon='paper plane outline' floated='right' style={{ marginTop: '5px' }} size='tiny' disabled={!this.state.expiration} onClick={() => { this.setState({ expiration: undefined, [popid]: false }); this.props.extendLicense(username, this.state.selectedLicense, this.state.expiration) }} />
    </React.Fragment>
    let popid = 'pop_extendlicense';

    return (
      <Popup
        trigger={<Button id={popid} color='teal' basic content='Extend License' icon='calendar' disabled={this.state.selectedLicense.length === 0} />}
        content={content}
        on='click'
        open={this.state[popid]}
        onClose={() => { this.setState({ [popid]: false }) }}
        onOpen={() => { this.setState({ [popid]: true }) }}
        position='left center'
      />
    )
  }

  renderTrafficConfig = (username, license, tconfig) => {
    let id = license + '_edittconfig';

    let adminContent = <Button basic color='blue' icon='pencil' label={{ basic: true, color: 'blue', content: tconfig }} labelPosition='left' size='small' onClick={() => { this.setState({ [id]: true, ntconfig: '' }) }} />;

    let userContent = <p>{tconfig}</p>

    let editConfig = <React.Fragment>
      <Input type='text' name='ntconfig' size='small' onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} placeholder={tconfig} value={this.state.ntconfig || ''} style={{ width: '250px' }} />
      <Button circular basic color='red' icon='dont' floated='right' style={{ marginTop: '5px' }} size='tiny' onClick={() => { this.setState({ [id]: undefined, }) }} />
      <Button circular basic color='green' icon='paper plane outline' floated='right' style={{ marginTop: '5px' }} size='tiny' onClick={() => { this.props.amendTrafficConfig(username, license, this.state.ntconfig); this.setState({ [id]: undefined, }) }} />
    </React.Fragment>

    return (
      typeof this.state[id] === 'undefined' ? this.props.usertype === 'admin' ? adminContent : userContent : editConfig
    )
  }

  renderChangeUser = (username) => {
    let options = [];
    typeof this.props.users !== 'undefined' && this.props.users.map((user) => (user !== username && options.push({ key: user, value: user, text: user })));
    let popid = 'pop_changeuser';

    let content = <React.Fragment>
      <Select options={options} name='target_user' value={this.state.target_user} onChange={(e, data) => { this.setState({ [data.name]: data.value }) }} />
      <Button circular basic color='red' icon='dont' floated='right' style={{ marginTop: '5px' }} size='tiny' onClick={() => { this.setState({ [popid]: false }) }} />
      <Button circular basic color='green' icon='paper plane outline' floated='right' style={{ marginTop: '5px' }} size='tiny' disabled={typeof this.state.target_user === 'undefined'} onClick={() => { this.props.changeLicenseUser(username, this.state.target_user, this.state.selectedLicense); this.setState({ [popid]: false, selectedLicense: [] }) }} />
    </React.Fragment>


    return (
      <Popup
        trigger={<Button color='orange' basic icon='user md' content='Change Owner' disabled={this.state.selectedLicense.length === 0} />}
        content={content}
        on='click'
        open={this.state[popid]}
        onClose={() => { this.setState({ [popid]: false }) }}
        onOpen={() => { this.setState({ [popid]: true, target_user: undefined }) }}
        position='left center'
      />
    )
  }

  renderDatasTable = () => {
    let temp = this.state.filter_tconfig === '' ? this.props.datas : this.props.datas.filter((element) => { return element.tconfig.includes(this.state.filter_tconfig) })

    return (
      <div style={{ height: window.innerHeight * 0.85, overflowY: 'auto', marginTop: '10px' }} >
        <Table celled selectable textAlign='center' verticalAlign='middle' color={colors[Math.floor(Math.random() * 13)]} singleLine >
          <Table.Header >
            <Table.Row>
              <Table.HeaderCell ><Checkbox checked={this.state.selectedLicense.length === this.props.datas.length} indeterminate={this.state.selectedLicense.length !== 0 && this.state.selectedLicense.length !== this.props.datas.length} onClick={() => { let temp = []; if (this.state.selectedLicense.length === 0) { this.props.datas.forEach((license) => temp.push(license.license)) } this.setState({ selectedLicense: temp }) }} /></Table.HeaderCell>
              <Table.HeaderCell >License</Table.HeaderCell>
              <Table.HeaderCell >Expired</Table.HeaderCell>
              <Table.HeaderCell >History</Table.HeaderCell>
              <Table.HeaderCell >Verify Within Month</Table.HeaderCell>
              <Table.HeaderCell >Traffic Config</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {temp.map((per_license, index) => (
              <Table.Row key={index}>
                <Table.Cell><Checkbox label={index + 1} checked={this.state.selectedLicense.includes(per_license.license)} onChange={() => { this.state.selectedLicense.includes(per_license.license) ? this.setState({ selectedLicense: this.state.selectedLicense.filter((element) => { return element !== per_license.license }) }) : this.setState({ selectedLicense: this.state.selectedLicense.concat([per_license.license]) }) }} /></Table.Cell>
                <Table.Cell>{per_license.license}</Table.Cell>
                <Table.Cell>{per_license.expired}</Table.Cell>
                <Table.Cell>{this.renderHistoryModal(per_license)}</Table.Cell>
                <Table.Cell>{per_license.count_m}</Table.Cell>
                <Table.Cell>{this.renderTrafficConfig(this.props.showuser, per_license.license, per_license.tconfig)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    )
  }

  preDownloadLicense = (licenses) => {
    this.setState({ download_loading: true });

    var JSZip = require("jszip");
    var zip = new JSZip();

    this.props.datas.forEach(element => {
      if (licenses.includes(element.license)) {
        let temp = {};
        temp['license'] = element.license;
        temp['serial'] = element.serial;
        temp['username'] = element.username;
        temp['password'] = element.password;
        zip.file(element.license + '_license.json', JSON.stringify(temp))
      }
    });

    zip.generateAsync({ type: "base64" }).then(function (base64) {
      var element = document.createElement('a');
      element.href = 'data:application/zip;base64,' + base64;
      element.download = 'licenses.zip'
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }).then(() => {
      this.setState({ download_loading: false })
    })

  }


  render() {
    return (
      <div>
        <Sticky context={this.contextRef} pushing >
          <Button.Group labeled icon >
            this.props.usertype === 'admin' &&
            {this.props.usertype === 'admin' && this.renderChangeUser(this.props.showuser)}
            {this.props.usertype === 'admin' && this.renderExtendPop(this.props.showuser)}
            <Button icon={!this.state.download_loading && 'cloud download'} content={!this.state.download_loading && 'Download'} loading={this.state.download_loading} basic color='pink' disabled={this.state.selectedLicense.length === 0} onClick={() => { this.preDownloadLicense(this.state.selectedLicense) }} />
          </Button.Group>

          <Input name='filter_tconfig' icon={<Icon name='search' inverted circular />} placeholder='Search Traffic Config' style={{ float: 'right' }} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} />

        </Sticky>
        {typeof this.props.datas == 'undefined' && <Loader active inline='centered' size='large' />}
        {typeof this.props.datas !== 'undefined' && this.renderDatasTable()}
      </div >
    )
  }
}

export default UserPage