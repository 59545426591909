import { connect } from "react-redux";
import LoginForm from "../views/LoginForm";

function mapStateToProps(state, ownProps) {
  return {
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
});

const LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);

export default LoginFormContainer;