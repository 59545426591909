import React from 'react'
import { Button, Card, Popup, Input, Loader, List } from 'semantic-ui-react'
import UserPage from '../containers/UserPageContainer.js';

const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
]

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
    if (typeof this.props.users === 'undefined') this.props.retrieveUsers();
  }

  renderNewLicensePop = (username) => {
    let input = <React.Fragment>
      <Input type='date' name='expiration' size='small' onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} value={this.state.expiration || ''} style={{ width: '300px' }} icon='calendar plus outline' iconPosition='left' />
      <Input type='text' name='tconfig' size='small' label={{ content: 'Traffic Config', basic: true }} labelPosition='right' onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} value={this.state.tconfig || ''} style={{ width: '300px' }} />
      <Input type='text' name='quantity' size='small' label={{ content: 'Licenses', basic: true }} labelPosition='right' onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} value={this.state.quantity || ''} style={{ width: '300px' }} error={this.state.quantity > 15 || this.state.quantity < 1} />
      <Button circular basic color='red' icon='dont' floated='right' style={{ marginTop: '5px' }} size='tiny' onClick={() => { this.setState({ expiration: undefined, [popid]: false }) }} />
      <Button circular basic color='green' icon='paper plane outline' floated='right' style={{ marginTop: '5px' }} size='tiny' disabled={(typeof this.state.expiration === 'undefined' || this.state.expiration === '') || (this.state.quantity > 15 || this.state.quantity < 1)} onClick={() => { this.setState({ expiration: undefined, showloading: true }); this.props.createLicense(username, this.state.expiration, this.state.quantity, this.state.tconfig) }} />
    </React.Fragment>;
    let result = <React.Fragment>
      <List ordered>
        {typeof this.props.newlicense !== 'undefined' && this.props.newlicense.map((license, index) => (
          <List.Item key={index}>{license}</List.Item>
        ))}
      </List>
      <Button circular basic color='red' icon='dont' floated='right' style={{ marginTop: '5px' }} size='tiny' onClick={() => { this.setState({ [popid]: false, showloading: undefined }); this.props.cleanLicense() }} />
    </React.Fragment>;
    let loading = <React.Fragment>
      <Loader active inline='centered' size='tiny' />
    </React.Fragment>;

    let popid = username + '_pop';

    return (
      <Popup
        trigger={<Button id={popid} color='blue' basic>New License</Button>}
        content={typeof this.props.newlicense === 'undefined' ? !this.state.showloading ? input : loading : result}
        on='click'
        open={this.state[popid]}
        onClose={() => { this.setState({ [popid]: false, quantity: undefined }) }}
        onOpen={() => { this.setState({ [popid]: true, quantity: 1, tconfig: '' }) }}
        position='bottom center'
      />
    )
  }

  renderUserCard = () => {
    return (
      <React.Fragment>
        {typeof this.props.users === 'undefined' && <Loader active inline='centered' size='large' />}

        {typeof this.props.users !== 'undefined' && <Card.Group>
          {this.props.users.map((peruser, index) => (
            <Card key={index} color={colors[index]}>
              <Card.Content>
                <Card.Header textAlign='center'>{peruser}</Card.Header>
              </Card.Content>
              <Card.Content extra>
                <div className='ui two buttons'>
                  <Button basic color='green' onClick={() => { this.props.backtoadmin(peruser); }}>Licenses</Button>
                  {this.renderNewLicensePop(peruser)}
                </div>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        }
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        {(!this.props.showuser && this.props.location.pathname === '/') ? this.renderUserCard() :
          <React.Fragment>
            <UserPage {... this.props} />
          </React.Fragment>
        }

      </React.Fragment>
    )
  }
}

export default AdminPage