import React from 'react'
import { Button, Form, Grid, Image, Segment, Message } from 'semantic-ui-react'
import logo from '../img/CloudCoffer-logo-flat.jpg'

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      pwd: '',
      tempuser: { name: '', pwd: '' }
    };
  }

  componentDidMount = () => {
    this.props.history.replace({ pathname: '/' });
  }

  verifyInputError = (field) => {
    return (this.props.auth === false || this.props.auth === 'false') && this.state[field] === this.state.tempuser[field];
  }

  render() {
    return (
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image src={logo} style={{ maxWidth: 300 }} centered />
          <Form size='large' style={{ marginTop: 20 }}>
            <Segment stacked>
              <Form.Input name='name' fluid icon='user' iconPosition='left' placeholder='User name' value={this.state.name} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} error={this.verifyInputError('name')} />
              <Form.Input name='pwd' fluid icon='lock' iconPosition='left' placeholder='Password' type='password' value={this.state.pwd} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} error={this.verifyInputError('name')} />
              <Button onClick={() => { this.props.authLogin(this.state.name, this.state.pwd); this.setState({ tempuser: { 'name': this.state.name, 'pwd': this.state.pwd } }); }} color='teal' fluid size='large'>Login</Button>
            </Segment>
          </Form>
          <Message>
            <a href='https://www.cloudcoffer.com/'>Welcome to CloudCoffer!</a>
          </Message>
        </Grid.Column>
      </Grid>
    )
  }
}

export default LoginForm