import { connect } from "react-redux";
import App from "../App";
import { authLogin, SAVE_PERMISSION, createUser, SAVE_DATAS } from '../action/userAction'

function mapStateToProps(state, ownProps) {
  return {
    token: state.getIn(['data', 'token']),
    auth: state.getIn(['data', 'auth']),
    usertype: state.getIn(['data', 'usertype']),
    failed: state.getIn(['data', 'failed'])
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  reloadPermission: (token) => { dispatch({ type: SAVE_PERMISSION, payload: { token: token } }); },
  authLogin: (name, pwd) => {
    dispatch(authLogin(name, pwd));
  },
  authLogout: () => {
    dispatch({ type: SAVE_PERMISSION, payload: {token: undefined} });
  },
  createUser: (name, pwd) => {
    dispatch(createUser(name, pwd));
  },
  cleanDatas: () => {
    dispatch({ type: SAVE_DATAS, payload: { datas: undefined } });
  }
});

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;