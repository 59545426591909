import { connect } from "react-redux";
import UserPage from "../views/UserPage";
import { retrieveHistory, SAVE_HISTORY, extendLicense, retrieveUser, amendTrafficConfig, changeLicenseUser } from '../action/userAction'

function mapStateToProps(state, ownProps) {
  return {
    datas: state.getIn(['data', 'datas']),
    history: state.getIn(['data', 'history']),
    token: state.getIn(['data', 'token']),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  retrieveHistory: (license) => {
    dispatch(retrieveHistory(license));
  },
  cleanHistory: () => {
    dispatch({ type: SAVE_HISTORY, payload: { history: undefined } });
  },
  extendLicense: (username, license, expired_date) => {
    dispatch(extendLicense(username, license, expired_date));
  },
  retrieveUser: (username) => {
    dispatch(retrieveUser(username));
  },
  amendTrafficConfig: (username, license, ntconfig) => {
    dispatch(amendTrafficConfig(username, license, ntconfig));
  },
  changeLicenseUser: (currentuser, newuser, license) => {
    dispatch(changeLicenseUser(currentuser, newuser, license));
  }
});

const UserPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPage);

export default UserPageContainer;