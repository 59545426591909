export const SAVE_PERMISSION = 'SAVE_PERMISSION'
export const SAVE_USERS = 'SAVE_USERS'
export const SAVE_HISTORY = 'SAVE_HISTORY'
export const SAVE_DATAS = 'SAVE_DATAS'
export const CONNECT_FAILED = 'CONNECT_FAILED'
export const SAVE_LICENSE = 'SAVE_LICENSE'

const postAction = (requesturi, postData, successAction) => (dispatch, getState) => {
  if (getState().getIn(['data', 'token'])) postData['access_token'] = getState().getIn(['data', 'token']);
  var myInit = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(postData)
  };

  fetch(requesturi, myInit).then(function (response) {
    return response.json();
  }).then(function (data) {
    if (typeof successAction !== 'undefined') dispatch({ type: successAction, payload: data });
  }).catch(() => dispatch({ type: CONNECT_FAILED }));
}


export const authLogin = (name, pwd) => (dispatch, getState) => {
  dispatch(postAction('/login', { 'username': name, 'password': pwd }, SAVE_PERMISSION));
}

export const createUser = (name, pwd) => (dispatch, getState) => {
  dispatch(postAction('/add_newuser', { 'username': name, 'password': pwd }, SAVE_USERS));
}

export const retrieveHistory = (license) => (dispatch, getState) => {
  dispatch(postAction('/retrieve_history', { 'license': license }, SAVE_HISTORY));
}

export const retrieveUser = (username) => (dispatch, getState) => {
  dispatch(postAction('/retrieve_user', { 'username': username }, SAVE_DATAS));
}

export const extendLicense = (username, license, expired_date) => (dispatch, getState) => {
  dispatch(postAction('/extend_license', { 'username': username, 'license': license, 'expired_date': expired_date }, SAVE_DATAS));
}

export const createLicense = (username, expired_date, quantity, tconfig) => (dispatch, getState) => {
  dispatch(postAction('/add_newlicense', { 'username': username, 'expired_date': expired_date, 'quantity': quantity, 'tconfig': tconfig }, SAVE_LICENSE));
}

export const retrieveUsers = () => (dispatch, getState) => {
  dispatch(postAction('/retrieve_users', {}, SAVE_USERS));
}

export const amendTrafficConfig = (username, license, ntconfig) => (dispatch, getState) => {
  dispatch(postAction('/amend_trafficconfig', { 'username': username, 'license': license, 'ntconfig': ntconfig }, SAVE_DATAS));
}

export const changeLicenseUser = (currentuser, newuser, license) => (dispatch, getState) => {
  dispatch(postAction('/change_licenseuser', { 'currentuser': currentuser, 'newuser': newuser, 'license': license }, SAVE_DATAS));
}
