import { combineReducers } from "redux-immutable"
import { handleActions } from "redux-actions"
import { State } from "../constants/API"
import cookie from 'react-cookies'

const dataReducer = handleActions({
  SAVE_PERMISSION: (state, { payload }) => {
    let new_state = State;

    if (typeof payload.token !== 'undefined') {
      new_state = new_state.setIn(['token'], payload.token);
      cookie.save('token', payload.token);

      let parsedToken = JSON.parse(atob(payload.token.split('.')[1]));
      new_state = new_state.setIn(["auth"], parsedToken.identity.auth);
      new_state = new_state.setIn(["usertype"], parsedToken.identity.usertype);
    }
    else {
      cookie.remove('token');
      new_state = State
    }

    return new_state;
  },
  SAVE_USERS: (state, { payload }) => {
    let new_state = state.setIn(['users'], payload.users);
    return new_state;
  },
  SAVE_HISTORY: (state, { payload }) => {
    let new_state = state.setIn(['history'], payload.history);
    return new_state;
  },
  SAVE_DATAS: (state, { payload }) => {
    let new_state = state.setIn(['datas'], payload.datas);
    return new_state;
  },
  CONNECT_FAILED: (state, { payload }) => {
    let new_state = state.setIn(['failed'], 'CONNECT_FAILED');
    return new_state;
  },
  SAVE_LICENSE: (state, { payload }) => {
    let new_state = state.setIn(['newlicense'], payload.license);
    return new_state;
  },
}, State);

const rootReducer = combineReducers({
  data: dataReducer
});

export default rootReducer;