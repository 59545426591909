import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import rootReducer from './reducer/dataReducer'
import Immutable from 'immutable';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import './index.css';
import App from './containers/AppContainer.js';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css'

let initialState = Immutable.Map();
let store = (process.env.NODE_ENV === 'production') ? createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware))) : createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware, logger)));

ReactDOM.render(
  <Provider store={store}>
    <HashRouter >
      <Switch>
        <Route path='/' render={props => <App {...props} />} />
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
