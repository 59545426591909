import React, { createRef } from 'react';
import './App.css';
import { Menu, Button, Icon, Grid, Popup, Input, Sticky, Rail } from 'semantic-ui-react'

import LoginForm from './containers/LoginFormContainer.js';
import AdminPage from './containers/AdminPageContainer.js';
import UserPage from './containers/UserPageContainer.js';

const regname = /^[0-9A-Za-z_-]{1,20}$/;
const regpwd = /^[A-Za-z0-9!@#$%\\()^*+_\-=]{6,50}$/;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newuserPop: false,
      backtoadmin: false
    };
  }
  contextRef = createRef()

  componentDidMount = () => {
    let lifecheck = setInterval(() => {
      if (typeof this.props !== 'undefined' && this.props.token !== null && (Date.now() / 1000) >= JSON.parse(atob(this.props.token.split('.')[1])).exp) {
        if (this.state.backtoadmin) this.setState({ backtoadmin: false });
        if (this.state.showuser) this.setState({ showuser: undefined });
        this.props.authLogout();
        clearInterval(lifecheck);
      }
    }, 1000);

    if (this.props.token && (typeof this.props.auth === 'undefined' || typeof this.props.usertype === 'undefined')) this.props.reloadPermission(this.props.token);
    if (typeof this.state.showuser === 'undefined' && this.props.location.pathname !== '/') {
      let name = this.props.location.pathname.split('/admin/')[1];
      this.setState({ backtoadmin: true, showuser: name });
      this.props.history.push({ pathname: '/admin/' + name })
    }
  }

  renderNewUserPop = () => {
    let verifyName = (name) => {
      return name !== '' && regname.test(name);
    }

    let verifyPwd = (pwd) => {
      return pwd !== '' && regpwd.test(pwd);
    }

    return (
      <React.Fragment>
        <Input label='username' name='newuser_name' size='small' onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} value={this.state.newuser_name || ''} style={{ width: '250px' }} error={!verifyName(this.state.newuser_name)} />
        <Input label='password' name='newuser_pwd' size='small' onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} value={this.state.newuser_pwd || ''} style={{ width: '250px' }} error={!verifyPwd(this.state.newuser_pwd)} />
        <Button circular basic color='red' icon='dont' floated='right' onClick={() => { this.setState({ newuserPop: false, newuser_name: undefined, newuser_pwd: undefined }) }} style={{ marginTop: '5px' }} />
        <Button circular basic color='green' icon='paper plane outline' floated='right' style={{ marginTop: '5px' }} disabled={typeof this.state.newuser_name === 'undefined' || typeof this.state.newuser_pwd === 'undefined' || verifyName(this.state.newuser_name) !== true || verifyPwd(this.state.newuser_pwd) !== true} onClick={() => { this.props.createUser(this.state.newuser_name, this.state.newuser_pwd); this.setState({ newuserPop: false, newuser_name: undefined, newuser_pwd: undefined }); }} />
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        {(this.props.auth === null || this.props.auth === 'false' || !this.props.auth) ? <LoginForm {... this.props} authLogin={this.props.authLogin.bind(this)} /> :

          <React.Fragment>


            <div ref={this.contextRef}>
              <Sticky context={this.contextRef} pushing >
                <Menu inverted size='large' attached='top' >
                  <Menu.Item
                    name={this.props.auth}
                    active={typeof this.state.showuser === 'undefined'}
                  />
                  {this.state.showuser && <Menu.Item
                    name={this.state.showuser}
                    active
                  />}
                </Menu>
              </Sticky>

              <Grid centered columns={2} style={{ marginTop: '10px' }}>

                <Grid.Column width={10}>
                  {this.props.usertype === 'admin' && <AdminPage {...this.props} backtoadmin={(name) => { this.setState({ backtoadmin: true, showuser: name }); this.props.history.push({ pathname: '/admin/' + name }) }} showuser={this.state.showuser} />}
                  {this.props.usertype === 'user' && <UserPage {... this.props} />}

                  <Rail position='right' width={1} >
                    <Sticky context={this.contextRef} pushing offset={window.innerHeight * 0.8} style={{ marginTop: window.innerHeight * 0.8 }}>
                      <Button.Group vertical inverted floated='left' size='medium'>
                        {this.props.usertype === 'admin' && <React.Fragment>
                          {this.state.backtoadmin && <React.Fragment>
                            <Button animated onClick={() => { this.setState({ backtoadmin: false, showuser: undefined }); this.props.cleanDatas(); this.props.history.replace({ pathname: '/' }); }} color='teal' basic>
                              <Button.Content hidden>All Users</Button.Content>
                              <Button.Content visible><Icon name='level up alternate' /></Button.Content>
                            </Button>
                          </React.Fragment>}
                          <Popup
                            trigger={<Button animated color='teal' basic>
                              <Button.Content hidden>New User</Button.Content>
                              <Button.Content visible><Icon name='add user' /></Button.Content>

                            </Button>}
                            content={this.renderNewUserPop()}
                            on='click'
                            open={this.state.newuserPop}
                            onClose={() => { this.setState({ newuserPop: false }) }}
                            onOpen={() => { this.setState({ newuserPop: true }) }}
                            position='left center'
                          />
                        </React.Fragment>
                        }
                        <Button animated onClick={() => { this.props.authLogout(); this.setState({ backtoadmin: false, showuser: undefined }) }} color='red' basic>
                          <Button.Content hidden>Logout</Button.Content>
                          <Button.Content visible><Icon name='user close' /></Button.Content>
                        </Button>
                      </Button.Group>
                    </Sticky>
                  </Rail>

                </Grid.Column>
              </Grid>
            </div>

          </React.Fragment>
        }

      </React.Fragment>
    );
  }
}

export default App;
