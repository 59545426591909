import { connect } from "react-redux";
import AdminPage from "../views/AdminPage";
import { retrieveUser, createLicense, SAVE_LICENSE, retrieveUsers } from "../action/userAction";

function mapStateToProps(state, ownProps) {
  return {
    users: state.getIn(['data', 'users']),
    newlicense: state.getIn(['data', 'newlicense'])
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  retrieveUser: (username) => {
    dispatch(retrieveUser(username));
  },
  createLicense: (username, expiration, quantity, tconfig) => {
    dispatch(createLicense(username, expiration, quantity, tconfig));
  },
  cleanLicense: () => {
    dispatch({ type: SAVE_LICENSE, payload: { license: undefined } });
  },
  retrieveUsers: () => {
    dispatch(retrieveUsers());
  },
});

const AdminPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPage);

export default AdminPageContainer;